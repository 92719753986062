// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx": () => import("/vercel/path0/@narative/gatsby-theme-novela/src/templates/articles.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-articles-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-article-template-tsx": () => import("/vercel/path0/@narative/gatsby-theme-novela/src/templates/article.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-article-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-author-template-tsx": () => import("/vercel/path0/@narative/gatsby-theme-novela/src/templates/author.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-author-template-tsx" */),
  "component---narative-gatsby-theme-novela-src-templates-subscription-template-tsx": () => import("/vercel/path0/@narative/gatsby-theme-novela/src/templates/subscription.template.tsx" /* webpackChunkName: "component---narative-gatsby-theme-novela-src-templates-subscription-template-tsx" */)
}

