import prism from './prism';

export default {
  prism,
  primary: '#250902',
  secondary: '#896A67',
  accent: '#8B2635',
  grey: '#464655',
  background: '#fcfcfc',
  hover: 'rgba(0, 0, 0, 0.07)',
  gradient: 'linear-gradient(180deg, rgba(217, 219, 224, 0) 0%, #CEBEBE 100%)',
  articleText: '#151515',
  track: 'rgba(8, 8, 11, 0.3)',
  progress: '#250902',
  card: '#ffffff',
  error: '#EE565B',
  success: '#46B17B',
  errorBackground: 'rgba(238, 86, 91, 0.1)',
  horizontalRule: 'rgba(8, 8, 11, 0.15)',
  inputBackground: 'rgba(0, 0, 0, 0.05)',
  modes: {
    dark: {
      primary: '#d3cecc',
      secondary: '#c4b5b3',
      accent: '#e8baae',
      grey: '#bdbdc3',
      background: '#151515',
      hover: 'rgba(255, 255, 255, 0.07)',
      gradient: 'linear-gradient(180deg, #151515 0%, rgba(21, 21, 21, 0.7) 100%)',
      articleText: '#e9e6e6',
      track: 'rgba(255, 255, 255, 0.3)',
      progress: '#d3cecc',
      card: '#1f1e1e',
      error: '#EE565B',
      success: '#46B17B',
      errorBackground: 'rgba(238, 86, 91, 0.1)',
      horizontalRule: 'rgba(255, 255, 255, 0.15)',
      inputBackground: 'rgba(255, 255, 255, 0.07)',
    },
  },
};
